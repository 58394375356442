<template>
  <div class="game-menu d-md-none">
    <v-row no-gutters id="game-menu">
      <v-row no-gutters id="game-menu-full" class="game-menu-wrapper" style="width: 100%">
        <v-btn
          v-if="this.storageHomepageHotGames"
          @click="selectGameType('', -1)"
          height="auto"
          text
          :ripple="false"
          depressed
          :class="['game-menu-content font-weight-bold', [$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'], [dActiveGameType == 'homepageHot' ? 'selected' : '']]"
        >
          <div class="game-menu-image" :style="`--src: url('/static/svg/mobileMenu/Hot.svg'); width:28px; height:36px;`">
            <!--              <img :url="`../../assets/svg/side nav/hot-salefes.png`" class="side-menu-icon"/>-->
          </div>
          <div class="game-menu-title">{{ $t(`label.hotGames`) }}</div>
          <!--          </v-card>-->
        </v-btn>
        <v-btn
          v-for="(gameType, index) in storageGameProviderType"
          :key="gameType.type"
          @click="selectGameType(gameType, index)"
          height="auto"
          text
          :ripple="false"
          depressed
          :class="['game-menu-content font-weight-bold', [$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'], [dActiveGameType == gameType.type ? 'selected' : '']]"
        >
          <div class="game-menu-image" :style="`--src: url('/static/svg/mobileMenu/${gameType.type}.png')`">
            <!-- <img :url="`/static/svg/mobileMenu/${gameType.type}.svg`" :alt="gameType.type" class="side-menu-icon"/> -->
          </div>
          <div class="game-menu-title">{{ $t(`label.${gameType.type}_short`) }}</div>
        </v-btn>
      </v-row>
      <!--      <v-row no-gutters id="game-menu-mini" class="game-menu-wrapper fixed-top">-->
      <!--        <v-btn-->
      <!--          v-for="(gameType, index) in storageGameProviderType"-->
      <!--          :key="gameType.type"-->
      <!--          @click="selectGameType(gameType, index)"-->
      <!--          min-width="45px"-->
      <!--          height="auto"-->
      <!--          text-->
      <!--          :ripple="false"-->
      <!--          depressed-->
      <!--          class="game-menu-content font-weight-bold"-->
      <!--          :class="$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'"-->
      <!--        >-->
      <!--          <div>{{ $t(`label.${gameType.type}_short`) }}</div>-->
      <!--        </v-btn>-->
      <!--      </v-row>-->
    </v-row>
    <div class="mt-0 hidden-md-and-up game-card-box px-4 py-2" v-if="dActiveGameType == HomePageHotString">
      <div class="game-card-box-wrapper hotgame">
        <div
          class="game-card-box-item"
          :class="`gT_${HomePageHotString}`"
          v-for="(hotGames, i) in storageHomepageHotGames"
          :key="hotGames.code"
          @click="!isGameMaintenance(hotGames.type, hotGames.provider) ? launchGame(hotGames, false) : {}"
        >
          <!--          :src="hotGames.imageCdn == null ? `${mediaUrl}/game_icons/en/${hotGames.provider}/${hotGames.code}_${hotGames.id}.jpg` : `${hotGames.imageCdn}`"-->
          <v-img
            :src="hotGames.imageCdn == null ? `${mediaUrl}/game_icons/en/${hotGames.provider}/${hotGames.code}_${hotGames.id}.jpg` : `${hotGames.imageCdn}`"
            class="banner-img"
            :class="['homepageHot_img']"
            contain
          >
            <v-overlay v-if="isGameMaintenance(hotGames.type, hotGames.provider)" absolute opacity="0.8" class="gameMaintenanceOverlay">
              <label class="text-uppercase font-weight-bold">{{ $t(`label.underMaintenance`) }}</label>
            </v-overlay>
            <img src="/static/image/icon/hot-icon.png" class="img-hot-home pos_right_top" />
          </v-img>
          <!--        <span v-if="dActiveGameType == 'rng'" class="rng_providerLabel">{{ $t(`label.custom_${provider.providerCode}`) }}</span>-->
        </div>

        <div id="autoFillEle1" class="game-card-box-item gT_homepageHot" style="max-height: 0px !important">
          <v-img max-height="0" :src="'/static/image/rng_pp.jpg'" class=" banner-img"></v-img>
        </div>
        <div id="autoFillEle2" class="game-card-box-item gT_homepageHot" style="max-height: 0px !important">
          <v-img max-height="0" :src="'/static/image/rng_pp.jpg'" class=" banner-img"></v-img>
        </div>
      </div>
    </div>
    <div class="mt-0 hidden-md-and-up game-card-box px-4 py-2" v-else>
      <!--         :class="this.dActiveGameType == 'ld' || this.dActiveGameType == 'rng'|| this.dActiveGameType == 'crash' ? 'ld-gap' : ''">-->

      <div class="game-card-box-wrapper">
        <div class="game-card-box-item" :class="`gT_${dActiveGameType}`" v-for="provider in gameContainer1.providers" :key="provider.providerCode">
          <v-img
            @click="!isGameMaintenance(gameContainer1.type, provider.providerCode) ? accessGame(gameContainer1.type, provider.providerCode) : {}"
            contain
            class="game-card-image-avatar"
            :src="`${mediaUrl}/vendor-card/${gameContainer1.type}/${provider.providerCode}_avatar.png`"
            :alt="provider.providerCode"
            width="auto"
          >
            <v-overlay v-if="isGameMaintenance(gameContainer1.type, provider.providerCode)" absolute opacity="0.8" class="gameMaintenanceOverlay">
              <label class="text-uppercase font-weight-bold">{{ $t(`label.underMaintenance`) }}</label>
            </v-overlay>
            <img src="/static/image/icon/hot-icon.png" class="img-hot-home pos_right_top" alt="hot" v-if="isHotProvider(provider.providerCode)" />
            <img src="/static/image/icon/new_icon.png" class="img-new-home pos_right_top" alt="new" v-if="isNewProvider(provider.providerCode)" />
            <!--            <v-icon color="#f56909" class="pos_right_top" v-if="isHotProvider(provider.providerCode)">mdi-fire-->
            <!--            </v-icon>-->
          </v-img>
          <label class="text-capitalize d-flex justify-center holder_text">{{ $t(`label.custom_${provider.providerCode}`) }}</label>
        </div>
      </div>
    </div>
    <!--    <div class="game-card-box px-4 py-2">-->
    <!--      &lt;!&ndash; <div class="game-card-label mb-4 d-md-none">{{ $t(`label.${gameContainer1.type}`) }}</div> &ndash;&gt;-->
    <!--      <div class="game-card-box-wrapper">-->
    <!--        <div class="game-card-box-item" v-for="provider in gameContainer1.providers" :key="provider.providerCode">-->
    <!--          <v-img-->
    <!--            @click="!isGameMaintenance(gameContainer1.type, provider.providerCode) ? accessGame(gameContainer1.type, provider.providerCode) : {}"-->
    <!--            contain-->
    <!--            class="game-card-image-avatar"-->
    <!--            :src="`${mediaUrl}/vendor-card/${gameContainer1.type}/${provider.providerCode}_avatar.png`"-->
    <!--            :alt="provider.providerCode"-->
    <!--            width="auto"-->
    <!--          >-->
    <!--            <v-overlay v-if="isGameMaintenance(gameContainer1.type, provider.providerCode)" absolute opacity="0.8" class="gameMaintenanceOverlay">-->
    <!--              <label class="text-uppercase font-weight-bold">{{ $t(`label.underMaintenance`) }}</label>-->
    <!--            </v-overlay>-->
    <!--            <img src="/static/image/icon/hot-icon.png" class="img-hot-home pos_right_top" v-if="isHotProvider(provider.providerCode)" />-->
    <!--            <img src="/static/image/icon/new_icon.png" class="img-new-home pos_right_top" v-if="isNewProvider(provider.providerCode)" />-->
    <!--            &lt;!&ndash;            <v-icon color="#f56909" class="pos_right_top" v-if="isHotProvider(provider.providerCode)">mdi-fire&ndash;&gt;-->
    <!--            &lt;!&ndash;            </v-icon>&ndash;&gt;-->
    <!--          </v-img>-->
    <!--          <label class="text-capitalize d-flex justify-center holder_text">{{ $t(`label.custom_${provider.providerCode}`) }}</label>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <!-- <div :class="$vuetify.breakpoint.mdAndDown ? 'pt-0 pb-4' : 'py-4'">
  <v-row class="d-flex" no-gutters justify="space-around">
    <v-col cols="12">
      <v-card class="game-card-menu">
        <v-row no-gutters justify="space-around">
          <v-btn v-for="(gameType, index) in storageGameProviderType" :key="gameType.type"
                 @click="selectGameType(gameType, index)" class="pt-2 pb-1 px-0 page-navigator-button"
                 min-width="45px" height="auto" text :ripple="false" light depressed>
            <v-row no-gutters>
              <v-col cols="12" class="py-0 text-center">
                <v-divider v-show="gameType.selected" class="game-menu-divider mx-2"></v-divider>
              </v-col>
              <v-col cols="12" class="px-0 pb-1 text-center">
                <label class="game-menu-title px-2 font-weight-bold">{{ $t(`label.${gameType.type}_short`) }}</label>
              </v-col>
            </v-row>
          </v-btn>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  <v-row class="d-flex mt-0 pt-0" justify="space-around">
    <v-col md="6" sm="12" class="pt-0">
      <v-card
          :class="['game-card-container', gameContainer1.providers.length > 8 ? 'three' : gameContainer1.providers.length > 4 ? 'two' : 'one' ]">
      <v-img class="white--text align-end pa-0" :aspect-ratio="28/9"
               :src="`${mediaUrl}/vendor-banner/${gameContainer1.type}_banner.jpg`">
        </v-img>
        <v-card-text class="game-card-box">
          <v-row align="center">
            <v-col class="game-card-box-item text-center" v-for="provider in gameContainer1.providers"
                   :key="provider.providerCode" cols="3">
              <div>

                <v-img
                    @click="!isGameMaintenance(gameContainer1.type, provider.providerCode) ? accessGame(gameContainer1.type, provider.providerCode): {} "
                    contain class="game-card-image-avatar"
                    :src="`${mediaUrl}/vendor-card/${gameContainer1.type}/${provider.providerCode}_avatar.png`"
                    :alt="'JAYA9-'+ provider.providerCode" width=auto>
                  <v-overlay
                      v-if="isGameMaintenance(gameContainer1.type, provider.providerCode)"
                      absolute
                      opacity="0.8"
                      class="gameMaintenanceOverlay"
                  >
                    <label
                        class="yellow--text text-uppercase font-weight-bold">{{
                        $t(`label.underMaintenance`)
                      }}</label>
                  </v-overlay>
                </v-img>
                <p class="mt-lg-2">
                  <label
                      class="black--text text-capitalize text-center holder_text">{{
                      $t(`label.custom_${provider.providerCode}`)
                    }}</label>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" class="pt-0 hidden-sm-and-down">
      <v-card :class="['game-card-container', gameContainer2.providers.length > 8 ? 'three' : gameContainer2.providers.length > 4 ? 'two' : 'one' ]">
      <v-img class="white--text align-end pa-0" :aspect-ratio="28/9"
               :src="`${mediaUrl}/vendor-banner/${gameContainer2.type}_banner.jpg`">
        </v-img>
        <v-card-text class="game-card-box">
          <v-row align="center">
            <v-col class="game-card-box-item text-center" v-for="provider in gameContainer2.providers"
                   :key="provider.providerCode" cols="3">
              <div>
                <v-img class="game-card-image-avatar" v-if="gameContainer2.type == 'fishing'"
                       src="https://cdn.vuetifyjs.com/images/cards/sun.png" alt="JAYA9" width=auto></v-img>
                <v-img
                    @click="!isGameMaintenance(gameContainer2.type, provider.providerCode) ? accessGame(gameContainer2.type, provider.providerCode): {} "
                    class="game-card-image-avatar" v-else
                    :src="`${mediaUrl}/vendor-card/${gameContainer2.type}/${provider.providerCode}_avatar.png`"
                    :alt="'JAYA9-'+ provider.providerCode" width=auto>
                  <v-overlay
                      v-if="isGameMaintenance(gameContainer2.type, provider.providerCode)"
                      absolute
                      opacity="0.8"
                      class="gameMaintenanceOverlay"
                  >
                    <label
                        class="yellow--text text-uppercase font-weight-bold">{{
                        $t(`label.underMaintenance`)
                      }}</label>
                  </v-overlay>
                </v-img>
                <p class="mt-lg-2">
                  <label
                      class="black--text text-capitalize text-center holder_text">{{
                      $t(`label.custom_${provider.providerCode}`)
                    }}</label>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  </div> -->
</template>

<script>
import _ from 'lodash'
import { uiHelper } from '@/util'
import { CACHE_KEY, SESSION } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import { DevicePlatform } from '@/constants/enums'
import { SHARED } from '@/constants/constants'
import { SEMI_TRANSFER } from '@/store/transfer.module'
import { GAME_HOMEPAGE_HOT } from '@/store/game.module'
import _forEach from 'lodash/forEach'

export default {
  name: 'appGameCard',
  // props: {
  //     selectionNavigator: {
  //         type: String,
  //         required: true,
  //         default: 'all'
  //     },
  //     openLoginDialog: {
  //         type: Function,
  //         required: true,
  //         default: () => {}
  //     },
  //     gameType: {
  //         type: String,
  //         required: true,
  //         default: () => ''
  //     }
  // },
  data: () => ({
    routeName: ROUTE_NAME,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    storageHomepageHotGames: uiHelper.setLocalStorage(`${CACHE_KEY.HOMEPAGE_HOTGAMELIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    selectedGameCard: '',
    gameContainer1: {},
    gameContainer2: {},
    dActiveGameType: 'ld',
    HomePageHotString: 'homepageHot',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  }),
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    calctab() {
      return this.text.replace('Lorem', 'tab2')
    },
    ActiveMobileGameType() {
      return this.dActiveGameType
    },
    homepageHotGames() {
      return this.$store.state.game.HomepageHotGames
    }
  },
  watch: {
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
      this.selectGameType('', 0)
    },
    ActiveMobileGameType() {
      return this.dActiveGameType
    },
    homepageHotGames() {
      this.storageHomepageHotGames = this.$store.state.game.HomepageHotGames
    }
  },
  created() {
    this.selectGameType('', 0)
    this.getHomepageHotGames()
  },
  methods: {
    launchGame(gameInfo, isDemo) {
      if (!isDemo && !this.isLoggedIn) {
        this.openLoginDialog()
      } else {
        this.directLaunchGame(gameInfo, isDemo)
      }
    },
    async directLaunchGame(gameInfo, isDemo) {
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: gameInfo.provider,
          type: gameInfo.type,
          lang: this.$route.params.lang
        },
        query: {
          id: gameInfo.id,
          code: gameInfo.code,
          demo: isDemo ? 1 : 0
        }
      })
      try {
        this.$ga.event('Game', `${isDemo ? 'Demo' : 'Real'} Play`, `${gameInfo.provider.toUpperCase()} - Slot - ${gameInfo.locale.find(x => x.language == 'en').name}`, gameInfo.id)
      } catch (err) {
        console.log(err)
      }
      if (this.providerSelected == 'sb' || this.providerSelected == 'cricket') {
        window.open(routeData.href, '_blank')
        this.semiTransfer(gameInfo.provider)
      } else {
        window.open(routeData.href, '_blank')
        this.semiTransfer(gameInfo.provider).then(() => {})
      }
    },
    showAndroidToast() {
      // AndroidFunction.isAndroid();
      // AndroidFunction.isMobile()
      // Android.showToast(toast);
      // Android.biometric();
    },
    isNewProvider(provider) {
      let arr = ['jdb', 'haba', 'spribe', 'ludo', 'pt']
      return arr.indexOf(provider.toLowerCase()) > -1
    },
    isHotProvider(provider) {
      let arr = ['jili', 'evo', 'betswiz', 'pp', 'sexy_v2', 'spg', 'tito']
      return arr.indexOf(provider.toLowerCase()) > -1
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    tabfunc() {
      let t = this.tab.split('-')
      let index = parseInt(t[1]) + 1
      this.tab2 = 'tab-' + index
      console.log(this.tab2)
      console.log(this.tab)
    },
    selectGameType(gameType, index) {
      this.dActiveGameType = gameType != '' && gameType != undefined ? gameType.type : 'homepageHot'
      if (gameType == '' && index == -1) return

      uiHelper.setCookie(SESSION.FAVOURITE_GAME, index, 30)
      if (this.storageGameProviderType) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          if (index + 1 < this.storageGameProviderType.length) {
            this.gameContainer1 = this.storageGameProviderType[index]
            this.gameContainer2 = this.storageGameProviderType[index + 1]
          } else {
            this.gameContainer1 = this.storageGameProviderType[index - 1]
            this.gameContainer2 = this.storageGameProviderType[index]
          }
          _forEach(this.storageGameProviderType, function(gameType) {
            gameType.selected = false
          })
          this.gameContainer1.selected = true
          this.gameContainer2.selected = true
        } else {
          this.gameContainer1 = this.storageGameProviderType[index]
          this.gameContainer2 = this.storageGameProviderType[index]
          _forEach(this.storageGameProviderType, function(gameType) {
            gameType.selected = false
          })
          this.gameContainer1.selected = true
        }
      }
      console.log(this.gameContainer1)
    },
    getHomepageHotGames() {
      if (!this.storageHomepageHotGames) {
        let obj = {
          currency: uiHelper.getCurrency(),
          platform: uiHelper.getPlatform(),
          size: '',
          page: '',
          mode: 'All'
        }
        this.$store.dispatch(`${GAME_HOMEPAGE_HOT}`, { obj })
      }
    },
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance
      }
    },
    async accessGame(type, providerCode) {
      console.log(providerCode)
      this.showAndroidToast()
      let gaType = ''
      switch (type) {
        case 'sb':
          gaType = 'Sportsbook'
          break
        case 'fishing':
          gaType = 'Fishing'
          break
        case 'ld':
          gaType = 'Casino'
          break
        case 'special':
          gaType = 'Special'
          break
      }
      // if (!this.isLoggedIn && uiHelper.getPlatform() == DevicePlatform.MOBILE && type != 'rng' && type != 'special') {
      //     this.openLoginDialog()
      // }
      // else {
      if (type == 'rng') {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'lottery') {
        this.$router.push({
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'table') {
        this.$router.push({
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'special') {
        this.$router.push({
          name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else if (type == 'crash') {
        this.$router.push({
          name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        })
      } else {
        if (this.isLoggedIn) {
          let routeData = this.$router.resolve({
            name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
            params: {
              provider: providerCode,
              type: type
            },
            query: {
              demo: 0
            }
          })
          try {
            this.$ga.event('Game', 'Real Play', `${providerCode.toUpperCase()} - ${gaType}`)
          } catch (err) {
            console.log(err)
          }
          if (type == 'sb' || type == 'cricket') {
            await uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1440, 810)
            this.semiTransfer(providerCode)
          } else {
            this.semiTransfer(providerCode).then(() => {
              uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1440, 810)
            })
          }
        } else {
          this.openLoginDialog()
        }
      }
      //}
    },
    semiTransfer(providerCode) {
      let obj = {
        to_wallet: providerCode
      }
      return this.$store.dispatch(`${SEMI_TRANSFER}`, { obj })
    }
  },
  ready: function() {}
}
</script>

<style lang="scss">
.fixed-top {
  position: fixed;
  opacity: 0;
  top: 44px;
  width: 100%;
  background-color: var(--v-background-base);
  z-index: 1;
}

.transform-game-image {
  transform: translateY(-100px);
  transition: transform 0.5s ease-in-out;
}

.game-menu {
  background-color: var(--v-text_reverse-base);

  .game-menu-wrapper {
    max-width: 1240px;
    white-space: nowrap;
    margin: auto;
    display: block;
    overflow: auto;
    //display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--v-text-base);
    box-shadow: 2px 2px 12px -4px black;
  }

  .game-menu-content {
    min-width: auto !important;
    width: 78px;
    flex-grow: 1;
    text-transform: capitalize;
    padding: 10px 0px !important;
    background: white;
    color: var(--v-secondary-base);

    .v-btn__content {
      display: flex;
      flex-direction: column;
    }

    .game-menu-image {
      background-color: var(--v-secondary-base);
      -webkit-mask-image: var(--src);
      mask-image: var(--src);
      -webkit-mask-size: 100%;
      mask-size: 100%;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }

    .game-menu-title {
      margin-top: 8px;
      font-size: 12px;
    }
  }

  .game-menu-content:not(:last-child) {
    border-right: 1px solid #f3f3f3;
  }

  //.v-btn {
  //  border-radius: unset;
  //
  //  &:hover::before {
  //    opacity: 1;
  //    background: var(--v-secondary-base);
  //  }
  //
  //  &:hover {
  //    color: white;
  //
  //    .game-menu-image {
  //      background-color: white;
  //    }
  //  }
  //}
}

.game-menu {
  .v-btn {
    border-radius: unset;
  }

  .game-menu-content {
    &.selected {
      color: white;
      opacity: 1;
      background: var(--v-secondary-base);

      .game-menu-image {
        background-color: white;
      }
    }
  }
}

.game-card-box {
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-height: 130px;

  .game-card-label {
    position: relative;
    border-left: 4px solid var(--v-primary-base);
    padding-left: 4px;
  }

  .game-card-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    gap: 4px;
    animation: game-card-box-wrapper 0.5s ease-in-out;
    margin-top: 12px;

    .game-card-box-item {
      flex: 0 0 25%;
      max-width: 24%;
      padding: 4px;
      font-size: 12px;
      color: var(--v-secondary-base);
    }

    &.gT_homepageHot {
      min-width: 22%;
      max-width: 22%;
      height: auto !important;
      flex-direction: column;
      padding: 4px;
    }

    .homepageHot_img {
      height: auto;
      width: 100%;
    }
  }

  @keyframes game-card-box-wrapper {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
}

.pos_right_top {
  position: absolute !important;
  right: 3px;
  top: 2px;
}

.img-hot-home {
  width: 20px;
  animation: beat 1s infinite alternate;
}

.img-new-home {
  width: 30px;
  top: 7px;
  animation: beat 1s infinite alternate;
}

@keyframes beat {
  to {
    transform: scale(1.15);
  }
}
</style>
